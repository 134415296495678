import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/jsvectormap/dist/jsvectormap.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/flatpickr/dist/flatpickr.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/dropzone/dist/dropzone.css");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/nouislider/dist/nouislider.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/src/components/common/Loader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/src/components/Erroroundary.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/css/satoshi.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/css/simple-datatables.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/css/style.css");
